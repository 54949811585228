<template>
  <div class="content">

    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Gerenciar Packs</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12 card">
          <div class="card-body row">
            <div class="col-md-6">
              <base-input v-model="search.key" label="Nome da Pack"></base-input>
            </div>
            <div class="col-sm-3">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="item in [5,10,25,50]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label=" ">
                <base-button type="success" @click="find">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="tableData"
                        style="width: 100%">
                <el-table-column :min-width="60" label="ID">
                  <template slot-scope="props">
                      <span>
                        <span><b>#{{props.row.id}}</b></span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" label="Pack">
                  <template slot-scope="props">
                      <span>
                        <span>{{props.row.name}}</span>
                      </span>
                  </template>
                </el-table-column>
                <el-table-column :width="160" fixed="right" class-name="td-actions">
                  <template slot-scope="props">
                    <button type="button" class="btn btn-sm btn-info" size="sm" icon title="Roles"
                              @click="loadDataRoles(props.row.id, props.row.name)">
                      <i class="far fa-copy"></i>
                    </button>
                    <button v-show="props.row.name!=rolePackApi" type="button" class="btn btn-sm btn-danger" title="Usuários"
                              @click="loadDataUsers(props.row.id, props.row.name)">
                      <i class="far fa-user"></i>
                    </button>
                    <button v-show="props.row.name!=rolePackApi" type="button" class="btn btn-sm btn-warning" title="Editar"
                              @click="edit(props.row.id, props.row.name,
                              props.row.description.description, props.row.description.internalDetails)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info  mt-3">
              <p class="category"> Mostrando {{pagination.fromNumber}} até {{pagination.toNumber}}, de
                {{pagination.totalNumber}} registros.</p>
            </div>
            <div class="col-sm-6  mt-3">
              <p-pagination class="float-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.totalNumber"
                            :click="this.loadData">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="role.tableData.length>0" style="max-width: 800px; margin: 0 auto">
        <card>
          <h4>Roles da Pack <b>{{role.namePack}}</b></h4>
          <div class="card-body row">
            <div class="col-md-4">
              <base-input label="Nome da Role" v-model="role.authority"/>
            </div>
            <div class="col-sm-2">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="role.pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="itemRole in [5,10,25,50]"
                    :key="itemRole"
                    :label="itemRole"
                    :value="itemRole">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Status">
                <el-select size="large"
                           class="select-default"
                           placeholder="Selecionar"
                           v-model="role.status">
                  <el-option value="ALL"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="TRUE"
                             class="select-default"
                             label="Ativo"/>
                  <el-option value="FALSE"
                             class="select-default"
                             label="Inativo"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label=" ">
                <base-button type="success" @click="findRoles(role.idPack, role.namePack)">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12">
              <el-table class="table-striped"
                        header-row-class-name="text-primary"
                        :data="role.tableData">
                <el-table-column label="Role">
                  <template slot-scope="props">
                        <span>
                          <span><b>{{props.row.authority}}</b></span>
                        </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="Ativo">
                  <template slot-scope="props">
                    <input type="checkbox" v-model="props.row.isAssociateRole" @change="updateAssociateRoles(props.row.id, props.row.isAssociateRole)">
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">EXIBINDO DO {{role.pagination.fromNumber}} AO {{role.pagination.toNumber}}, DE
                {{role.pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="role.pagination.currentPage"
                            :per-page="role.pagination.perPage"
                            :total="role.pagination.totalNumber"
                            :click="findRolePagination">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="user.tableData.length>0" style="max-width: 800px; margin: 0 auto">
        <card>
          <h4>Usuários da Pack <b>{{user.namePack}}</b></h4>
          <div class="card-body row">
            <div class="col-md-4">
              <base-input v-model="user.username" label="Usuário"/>
            </div>
            <div class="col-sm-2">
              <base-input label="Exibir">
                <el-select
                  class="select-default"
                  v-model="user.pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-default"
                    v-for="itemPack in [5,10,25,50]"
                    :key="itemPack"
                    :label="itemPack"
                    :value="itemPack">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Status">
                <el-select size="large"
                           class="select-default"
                           placeholder="Selecionar"
                           v-model="user.status">
                  <el-option value="ALL"
                             class="select-default"
                             label="Todos"/>
                  <el-option value="TRUE"
                             class="select-default"
                             label="Ativo"/>
                  <el-option value="FALSE"
                             class="select-default"
                             label="Inativo"/>
                </el-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label=" ">
                <base-button type="success" @click="findUsers(user.idPack, user.namePack)">
                  <i slot="label" class="nc-icon nc-check-2"></i> Buscar
                </base-button>
              </base-input>
            </div>
            <div class="col-sm-12">
              <el-table class="table-striped"
                        header-row-class-name="text-primary"
                        :data="user.tableData">
                <el-table-column label="Username">
                  <template slot-scope="props">
                        <span>
                          <span><b>@{{props.row.username}}</b></span>
                        </span>
                  </template>
                </el-table-column>
                <el-table-column label="E-mail">
                  <template slot-scope="props">
                        <span>
                          <span>{{props.row.email}}</span>
                        </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="Ativo">
                  <template slot-scope="props">
                    <input type="checkbox" v-model="props.row.isAssociateUser" @change="updateAssociateUsers(props.row.username, props.row.isAssociateUser)">
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">EXIBINDO DO {{user.pagination.fromNumber}} AO {{user.pagination.toNumber}}, DE
                {{user.pagination.totalNumber}} REGISTROS.</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="user.pagination.currentPage"
                            :per-page="user.pagination.perPage"
                            :total="user.pagination.totalNumber"
                            :click="findUsersPagination">
              </p-pagination>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12" v-if="id!=''" style="max-width: 800px; margin: 0 auto">
      <card>
        <h4>Editar a Pack <b>{{name}}</b></h4>
        <form>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <label>Nome</label>
                <input type="text" class="form-control" maxlength="30" v-model="name" style="text-transform: uppercase"></input>
              </div>
            </div>
            <div class="row form-group" style="margin-top: 10px">
              <div class="col-sm-6">
                <label>Descrição</label>
                <textarea class="form-control" rows="5" maxlength="255" v-model="description"></textarea>
              </div>
              <div class="col-sm-6">
                <label>Detalhes Internos</label>
                <textarea class="form-control" rows="5" maxlength="1000" v-model="internalDetails"></textarea>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button type="info" @click.prevent="updatePack">Salvar</button>
          </div>
        </form>
      </card>
    </div>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Option, Select, Table, TableColumn} from 'element-ui'
  import PPagination from '../../../../src/components/UIComponents/Pagination.vue'
  import {callWs, failWs, postWs} from "../../../../src/ws.service"

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  export default {
    components: {
      PPagination
    },
    data() {
      return {
        rolePackApi: 'API PACK',
        search: {
          key: '',
          documentStatus: null,
          dateOrder: 'ASC'
        },
        id: '',
        name: '',
        description: '',
        internalDetails: '',
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        role: {
          authority: '',
          namePack: '',
          idPack: '',
          status: 'ALL',
          tableData: [],
          listIncludeIdRoles: [],
          listDeleteIdRoles: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          }
        },
        user: {
          username: '',
          namePack: '',
          idPack: '',
          status: 'ALL',
          tableData: [],
          listIncludeUsernames: [],
          listDeleteUsernames: [],
          pagination: {
            perPage: 5,
            currentPage: 1,
            pageMaxNumber: 1,
            fromNumber: 0,
            toNumber: 0,
            totalNumber: 0
          }
        }
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      find() {
        this.pagination.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.id = ''
        this.user.tableData = []
        this.role.tableData = []
        postWs("/role/get-all-packs",
          true, null,
          {
            name: this.search.key,
            pageNumber: this.pagination.currentPage,
            pageSize: this.pagination.perPage
          }, this.successLoadData,
          failWs)
        return this.pagination.currentPage
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      findRoles(id, name) {
        this.role.pagination.currentPage = 1
        this.loadDataRoles(id, name)
      },
      findRolePagination() {
        this.loadDataRoles(this.role.idPack, this.role.namePack)
      },
      loadDataRoles(id, name) {
        this.id = ''
        this.user.tableData = []
        if(name != this.role.namePack){
          this.role.pagination.currentPage = 1
        }
        this.role.namePack = name
        this.role.idPack = id
        postWs("/role/get-pack-roles",
          true, null,
          {
            id: id,
            name: this.role.authority,
            active: this.role.status,
            pageNumber: this.role.pagination.currentPage,
            pageSize: this.role.pagination.perPage
          }, this.successLoadDataRole,
          failWs)
        return this.role.pagination.currentPage
      },
      successLoadDataRole(response) {
        if(this.role.authority != '' && response.data.list.length == 0){
          this.role.authority = ''
          this.$swal({
            title: 'Aviso!',
            text: "Nenhuma role encontrada com esse nome!",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        } else {
          this.role.tableData = response.data.list
          this.role.pagination.totalNumber = response.data.totalNumber
          this.role.pagination.pageMaxNumber = response.data.pageMaxNumber
          if (this.role.pagination.totalNumber > 0) {
            this.role.pagination.fromNumber = ((this.role.pagination.perPage * (this.role.pagination.currentPage - 1)) + 1)
            this.role.pagination.toNumber = ((this.role.pagination.fromNumber + this.role.tableData.length) - 1)
          } else {
            this.role.pagination.fromNumber = 0
            this.role.pagination.toNumber = 0
          }
        }
      },
      updateAssociateRoles(id, active){
        if(active){
          this.role.listIncludeIdRoles = [id];
          this.role.listDeleteIdRoles = [];
        }else{
          this.role.listDeleteIdRoles = [id];
          this.role.listIncludeIdRoles = [];
        }

        postWs("/role/associate-pack-roles",
          true, null,
          {
            idPackRole: this.role.idPack,
            listIncludeIdRoles: this.role.listIncludeIdRoles,
            listDeleteIdRoles: this.role.listDeleteIdRoles
          }, this.wsOk,
          failWs)
      },
      findUsers(id, name) {
        this.user.pagination.currentPage = 1
        this.loadDataUsers(id, name)
      },
      findUsersPagination() {
        this.loadDataUsers(this.user.idPack, this.user.namePack)
      },
      loadDataUsers(id, name) {
        this.id = ''
        this.role.tableData = []
        if(name != this.user.namePack){
          this.user.pagination.currentPage = 1
        }
        this.user.namePack = name
        this.user.idPack = id
        postWs("/role/get-pack-users",
          true, null,
          {
            id: id,
            name: this.user.username,
            active: this.user.status,
            pageNumber: this.user.pagination.currentPage,
            pageSize: this.user.pagination.perPage
          }, this.successLoadDataUsers,
          failWs)
        return this.user.pagination.currentPage
      },
      successLoadDataUsers(response) {
        if(this.user.username != '' && response.data.list.length == 0){
          this.user.username = ''
          this.$swal({
            title: 'Aviso!',
            text: "Nenhum usuário encontrado com esse username!",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        } else {
          this.user.tableData = response.data.list
          this.user.pagination.totalNumber = response.data.totalNumber
          this.user.pagination.pageMaxNumber = response.data.pageMaxNumber
          if (this.user.pagination.totalNumber > 0) {
            this.user.pagination.fromNumber = ((this.user.pagination.perPage * (this.user.pagination.currentPage - 1)) + 1)
            this.user.pagination.toNumber = ((this.user.pagination.fromNumber + this.user.tableData.length) - 1)
          } else {
            this.user.pagination.fromNumber = 0
            this.user.pagination.toNumber = 0
          }
        }
      },
      updateAssociateUsers(username, active){
        if(active){
          this.user.listIncludeUsernames = [username];
          this.user.listDeleteUsernames = [];
        }else{
          this.user.listDeleteUsernames = [username];
          this.user.listIncludeUsernames = [];
        }

        postWs("/role/associate-pack-users",
          true, null,
          {
            idPackRole: this.user.idPack,
            listIncludeUsernames: this.user.listIncludeUsernames,
            listDeleteUsernames: this.user.listDeleteUsernames
          }, this.wsOk,
          failWs)
      },
      wsOk(response){
        return true;
      },
      edit(id, name, description, internalDetails){
        this.role.tableData = []
        this.user.tableData = []
        this.id = id
        this.name = name
        this.description = description
        this.internalDetails = internalDetails
      },
      updatePack(){
        callWs("/role/update-pack", true,
          "POST", null, true, null,
          {id: this.id, name: this.name.toUpperCase(),
            description: this.description, internalDetail: this.internalDetails},
          () => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Dados salvos com sucesso.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              timer: 5000,
              icon: 'success'
            })
          }, (error) => {
            this.buttonDisabled = false
            if (error.response.status == 409) {
              this.$swal({
                title: 'Aviso!',
                text: 'Nome já existe para outra Pack cadastrada!',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                timer: 5000,
                icon: 'warning'
              })
              return
            }
            this.$swal({
              title: 'Falha!',
              text: 'Falha ao atualizar a pack, verifique os dados e tente novamente!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          })
      }
    }
  }
</script>
<style lang="scss">
  .dzs-acc-search {
    .el-table .td-actions {
      button.btn {
        margin-right: 5px;
      }
    }

    .input-group .form-control {
      padding-left: 2px !important;
    }

    .input-group .input-group-prepend .input-group-text {
      padding: 5px 0 5px 10px !important;
      font-size: 14px !important;
    }

    .cell {
      font-size: 12px !important;
    }

    th {
      color: black !important;
    }
  }

  .btn.btn-primary{
    margin-right: 10px;
  }
</style>
